.closedSearch {
    position: fixed;
    top: 0;
    right: 0;
    width: 1px;
    height: 1px;
    transition: 500ms;
    margin: 0;
    padding: 0;
    overflow: visible;
    transition: 350ms ease-in;
    z-index: 8;
}
.openSearch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--dark);
    color: var(--light);
    z-index: 11;
    transition: 250ms ease-out;
}
#Search {
    margin: 0;
    padding: 8vh 0;
    padding-bottom: 14vh;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--dark);
    color: var(--light);
    width: 100%;
    min-height: 100vh;
}
#Search::-webkit-scrollbar {
    display: none!important;
}
.openSeach::-webkit-scrollbar {
    display: none!important;
}
#searchbox {
    position: fixed;
    top: 0;
    right: 0;
    min-width: 50px;
    min-height: 50px;
    max-width: 55%;
    max-height: 40vh;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--dark);
    transition: 400ms ease-in;
    overflow-y: scroll;
    border-radius: 10px;
    box-shadow: 1px 2px 3px #00000040;
    z-index: 12;
}
#searchbox::-webkit-scrollbar {
    display: none!important;
}
#searchbox:hover {
    background-color: var(--prim);
    transition: 200ms;
}
#closeSearch {
    z-index: 100!important;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 1.2rem;
    font-weight: 800;
    background-color: var(--dark);
    color: var(--prim);
    padding: 5px 10px;
    border-radius: 50px;
    border: 1px var(--prim) solid;
    box-shadow: 1px 2px 3px #00000040;
    transition: 400ms ease-out;
    cursor: pointer;
    z-index: 11;
}
.searchHead {
    font-size: 1.4rem;
    text-align: left;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    word-break: break-word;
}
.s1 {
    background-color: var(--prim);
    animation: slideup 400ms ease-in;
    overflow: visible!important;
}
.searchimg {
    max-width: 60%;
    max-height: 60px;
    border-radius: 10px;
    box-shadow: 1px 2px 3px #00000040;
}
.openSearch #searchbox:hover {
    background-color: var(--prim);
}
#relevance {
    list-style: none!important;
    max-height: 200px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr;
}
.resultSlide {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
}
.resultSlide::-webkit-scrollbar {
    display: none!important;
}
.result {
    border-radius: 12px;
    box-shadow: 1px 2px 3px #00000040;
    border: 3px solid var(--dark);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 33%;
    overflow: hidden;
    margin: 20px;
    padding: 0;
    width: 100%;
    background-color: var(--dark);
}
.searchResult {
    border-radius: 12px;
    box-shadow: 1px 2px 3px #00000040;
    border: 3px solid var(--dark);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 33%;
    min-width: 33%;
    overflow: hidden;
    margin: 20px;
    padding: 0;
    width: 100%;
    background-color: var(--dark);
    cursor: pointer;
    transition: 100ms ease-in;
}
.searchResult:hover {
    transition: 300ms ease-out;
    background-color: var(--prim);
    border: 3px solid var(--dark)!important;
}
.searchResult span {
    font-weight: 800;
    text-align: center!important;
    color: var(--prim);
    cursor: pointer;
}
.onlineNOW {
    border: 3px solid var(--prim)
}
.onlineNOW span {
    color: var(--light)!important;
}
.resultSlide span {
    color: var(--light);
    text-align: left;
}
.result image-find {
    max-width: 66%;
    border-radius: 60px;
    overflow: hidden;
}
.searched image-find {
    max-width: 66%;
    border-radius: 60px;
    overflow: hidden;
}
.result span {
    font-size: 1.2rem;
    font-weight: 800;
    color: var(--light);
    text-align: center;
}
#relevance li {
    max-height: 60px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px 0px;
}
.searched {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
}
.searchResult image-find {
    max-width: 66%;
}
.s1::-webkit-scrollbar {
    display: none!important;
}
@media screen and (min-width: 850px) {
    .resultSlide {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%!important;
        overflow-x: hidden!important;
    }
    .searchResult image-find {
        max-width: 100px;
    }
    .resultSlide .searchResult {
        max-width: 100%;
        padding: 20px;
        flex-direction: row;
        margin: 0;
    }
    .searchResult span {
        font-size: 1.4rem;
    }
    .s1 {
        overflow-y: scroll!important;
    }
}