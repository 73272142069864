:root {
  --prim: #74bf9e;
  --second: #4f805c;
  --dark: #1a1c1a;
  --light: #eef4f0;
  --grey: #acb4ae;
  --warn: #e89a3f;
}
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
h1 {
  cursor: default;
  font-size: 2rem;
  font-weight: 800;
}
h2 {
  cursor: default;
}
h3 {
  cursor: default;
}
h5 {
  cursor: default;
}
h4 {
  cursor: default;
}
h6 {
  cursor: default; 
}
p {
  cursor: default;
}
.hidden {
  display: none!important;
}
body {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  background: url('https://images.unsplash.com/photo-1591804671002-b24e17464f9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--light);
  min-height: 100vh;
}
body::-webkit-scrollbar {
  display: none;
}
.App {
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 98vh;
  background-color: var(--light);
}
.App::-webkit-scrollbar {
  display: none;
}
.messages {
  position: fixed;
  bottom: 0;
  right: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--dark);
  width: 100%;
  z-index: 1;
  box-shadow: 1px 2px 3px #00000040;
  border-radius: 12px;
  max-height: 90vh;
  animation: slideup 300ms ease-out 0s 1 running normal;
}
#cont {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 20px 0;
  border-radius: 15px;
  background-color: var(--light);
}
#chat {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: var(--dark);
  color: var(--light);
  border: 3px var(--prim) solid;
  min-height: 100px;
  height: auto;
  max-height: 250px;
  border-radius: 12px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0px;
}
#un {
  color: var(--prim);
  font-size: .8rem;
  font-weight: 800;
  top: 0;
  left: 5%;
  margin: 0;
  padding: 0;
  cursor: default;
}
#chat::-webkit-scrollbar {
  display: none;
}
#message {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 100px;
  background-color: var(--prim);
  border-radius: 12px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
#message::-webkit-scrollbar {
  display: none;
}
.message {
  color: var(--light);
  width: 100%;
  max-width: 100%;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.2rem;
  word-break: break-all;
  margin-right: 10px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 7px;
  cursor: default;
}
.right {
  color: var(--light);
  width: 100%;
  max-width: 100%;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.2rem;
  word-break: break-all;
  margin-right: 10px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 7px;
}
textarea {
  max-width: 80%;
  width: 80%;
  border-radius: 15px;
  padding: 10px;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 5px;
  text-align: right;
  font-size: 1rem;
  position: relative;
  color: var(--prim);
  background-color: var(--dark);
}
#profilePic {
  overflow: hidden;
  max-width: 50%;
  max-height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  border-bottom-left-radius: 12px;
}
#profilePic img-f {
  max-width: 33%;
  border-radius: 6em;
  overflow: hidden;
  box-shadow: 1px 2px 3px #00000040;
}
#nav {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 33%;
  padding: 10px;
  overflow: hidden;
  background-color: var(--light);
  border-bottom-right-radius: 12px;
  transition: 200ms;
  animation: fadedown 400ms ease-out;
  z-index: 8
}
#nav .button {
  font-size: 1rem;
  background-color: var(--dark);
  color: var(--light);
  transition: 300ms;
}
#nav .button:hover{
  color: var(--light);
  background-color: var(--prim);
  letter-spacing: 0px;
}
#navlist {
  list-style-type: none;
  background-color: var(--dark);
  border-radius: 12px;
  box-shadow: 1px 2px 3px #00000040;
  padding: 10px 0px;
}
#navlist::-webkit-scrollbar {
  display: none;
}
#navlist li {
  font-weight: 800;
  color: var(--light);
  transition: 300ms;
  border-radius: 12px;
  cursor: pointer;
  margin: 5px 0px;
}
#navlist li:hover {
  color: var(--prim);
}
textarea::-webkit-scrollbar {
  display: none!important;
}
#im-form {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 10px 0;
  width: 100%;
}
h2 {
  text-transform: capitalize;
  font-weight: 800;
  color: var(--light);
  text-shadow: 1px 2px 2px #00000060;
  margin: 0;
  padding: 10px;
  max-width: 100%;
}
h5 {
  font-size: 1.2rem;
}
.imgbox {
  transition: 1s;
  cursor: pointer;
  margin: 20px 0;
  max-width: 60%;
}
.imgbox:hover {
  transform: translateX(-5px);
  transform: translateY(-5px);
}
.popbox {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark);
  position: fixed;
  top: 0;
  left: 0;
  transition: 300ms ease-in;
  z-index: 99;
  min-height: 100vh;
  cursor: default;
}
p {
  font-size: 1rem;
  margin: 0;
  padding: 5px 10px;
  max-width: 100%;
  background-color: var(--light);
  border-radius: 12px;
  color: var(--dark);
  font-weight: 600;
  text-align: left;
}
button {
  font-size: 1.2rem;
  margin: 5px;
  padding: 10px 15px;
  max-width: 100%;
  background-color: var(--dark);
  color: var(--light);
  transition: 400ms ease-in;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 1px 2px 3px #00000040;
  font-weight: 800;
}
button:hover {
  background-color: var(--dark);
  color: var(--prim);
  transition: 250ms ease-out;
  letter-spacing: 1px;
}
.button {
  font-size: 1.2rem;
  margin: 5px;
  padding: 10px 15px;
  max-width: 100%;
  background-color: var(--dark);
  color: var(--light);
  transition: 400ms ease-in;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 1px 2px 3px #00000040;
  font-weight: 800;
}
.button:hover {
  background-color: var(--dark);
  color: var(--prim);
  transition: 250ms ease-out;
  letter-spacing: 1px;
}
.imgBox {
  max-width: 50%;
}
span {
  text-align: right;
  width: 100%;
  margin: 0;
  font-size: .8rem;
  color: var(--prim);
  cursor: default;
}
.rightspan {
  text-align: right!important;
}
#chat span {
  text-align: left;
}
strong {
  color: var(--prim);
  font-weight: 400;
  font-size: .8rem;
}
#clear {
  cursor: pointer;
}
#clear:hover {
  color: var(--prim);
}
.SC {
  max-width: 80%;
  padding: 10px;
  background-color: var(--light);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 1px 2px 3px #00000050;
  border: 3px var(--prim) solid;
  animation: 1000ms ease-in 0 1 normal running fadedown;
}
#loginHead {
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: var(--prim);
  cursor: default;
}
#signup {
  background-color: var(--dark);
}

#signup label {
  color: var(--prim);
}
#signup #tog {
  color: var(--light);
}
#signup #tog:hover {
  color: var(--prim);
}
#signup h5 {
  color: var(--prim);
}
#signup .bind1 {
  margin: 10px 0px;
}
#signupHead {
  font-size: 1.3rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: var(--light);
  text-shadow: 1px 2px 3px #00000040;
  cursor: default;
}
#loginform {
  background-color: var(--light);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  width: 100%;
}
#loginform label {
  color: var(--dark);
  font-weight: 800;
}
.bind {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
input {
  background-color: var(--dark);
  color: var(--prim);
  font-size: 1.2rem;
  border-radius: 5px;
  width: 90%;
}
label {
  text-align: left;
  width: 100%;
  font-size: .8rem;
  color: darkgrey;
}
#loginsubmit {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--light);
  text-shadow: 1px 1px 3px var(--dark);
  box-shadow: 2px 2px 2px var(--second);
  background-color: var(--prim);
  border-radius: 5px;
  transition: 300ms ease-in;
}
#loginsubmit:hover {
  background-color: var(--dark);
  color: var(--prim);
  transition: 200ms ease-out;
}
#signupsubmit {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--light);
  text-shadow: 1px 1px 3px var(--dark);
  box-shadow: 2px 2px 2px var(--second);
  background-color: var(--prim);
  border-radius: 5px;
  transition: 300ms ease-in;
}
#signupsubmit:hover {
  background-color: var(--dark);
  color: var(--prim);
  transition: 200ms ease-out;
}
#toggle {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}
#signIn {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  margin: 0;
  padding: 0;
  animation: fadedown 1s ease-out 0s 1 running normal;
}
#tog {
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 1rem;
  cursor: pointer;
  transition: 300ms ease;
  color: var(--prim);
}
#tog:hover {
  color: var(--dark);
}
#loginnotif {
  color: var(--warn);
}
#signupnotif {
  color: var(--warn);
}
h5 {
  margin: 0px;
  padding: 0px;
}
#CIM {
  position: fixed;
  top: 5%;
  right: 2%;
}
#EI {
  background-color: var(--prim);
}
#EI:hover {
  color: var(--prim);
  background-color: var(--dark);
}
#DI {
  background-color: var(--warn);
}
#DI:hover {
  background-color: var(--dark);
  color: var(--warn);
}
.imagesCont {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  min-height: 98vh;
  max-height: 100vh;
  display: inline-flex;
  background-color: var(--light);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  animation: 300ms ease-in 0s 1 running normal slideup;
}
.imagesCont::-webkit-scrollbar {
  display: none;
}
.sub:hover {
  background-color: var(--prim);
  color: var(--dark);
  transition: 250ms ease-out;
}
#chats::-webkit-scrollbar {
  display: none;
}
.newtodo {
  position: fixed;
  top: 5%;
  right: 5%;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: var(--dark);
  color: var(--prim);
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px var(--prim) solid;
  box-shadow: 1px 2px 3px #00000040;
  transition: 400ms ease-out;
  cursor: pointer;
  z-index: 11;
}
.lefttodo {
  position: fixed;
  top: 5%;
  left: 5%;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: var(--dark);
  color: var(--prim);
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px var(--prim) solid;
  box-shadow: 1px 2px 3px #00000040;
  transition: 400ms ease-out;
  cursor: pointer;
  z-index: 11;
}
edit-image {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  overflow-x: hidden;
  background-color: var(--dark);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  animation: slideup 1s ease-out 0s 1 normal running;
  z-index: 9;
}
#chatMen {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
#chats {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  max-width: 60%;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 0;
  padding: 0;
}
#chattog {
  width: auto;
  max-width: 100%;
  text-align: right;
  padding: 5px;
  cursor: pointer;
  background-color: var(--prim);
  color: var(--light);
  box-shadow: 1px 2px 3px #00000040;
  text-shadow: 1px 2px 3px #00000040;
  border-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 6px;
  align-self: flex-end;
  justify-self: flex-start;
  transition: 300ms ease;
  animation: 600ms ease 100ms 1 normal running slideright;
}
#chattog:hover {
  background-color: var(--dark);
  border: 2px solid var(--prim);
  color: var(--light);
}
.AC {
  background-color: var(--prim);
  color: var(--light)!important;
}
.AC:hover {
  background-color: var(--prim);
  color: var(--light)!important;
  letter-spacing: 0!important;
}
#userchats {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cbut {
  margin: 0 5px;
  padding: 5px;
  word-break: break-all;
  font-size: .8rem;
  font-weight: 800;
  color: var(--prim);
  transition: 200ms;
  cursor: pointer;
}
.cbut:hover {
  background-color: var(--prim);
  color: var(--dark);
  letter-spacing: 1px;
}
#imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10vh;
  max-height: 80vh;
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
}
#imageGrid::-webkit-scrollbar {
  display: none!important;
}
#imageGrid h5 {
  margin-top: 10vh;
}
#profCreate {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: var(--dark);
  color: var(--light);
  z-index: 9;
  animation: slideup 800ms ease-out;
}
#profCreate::-webkit-scrollbar {
  display: none!important;
}
#form1 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
#form1 label {
  color: var(--prim);
  font-weight: 800;
  font-size: .8rem;
  text-align: center;
  max-width: 33%;
  word-break: break-word;
}
#form1 input {
  font-size: 1.2rem;
  box-shadow: 1px 0px 2px var(--prim);
}
#form1 .b2 {
  max-width: 60%;
}
.CPUF {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
#profCreate #closenewimage {
  display: none!important;
}
#PPreview {
  max-width: 33%;
}
#profCreate::-webkit-scrollbar{
  display: none!important;
}
#profCreate .newtodo{
  z-index: 1001;
}
.wrap {
  flex-wrap: wrap;
}
.imageForm {
  background-color: var(--light);
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 10px 0px;
  overflow-x: hidden;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  min-height: 100vh;
}
.b2 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
}
.b1 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
}
#popupform {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: var(--light);
  transition: 200ms ease-out;
  animation: slideup 350ms ease-in-out 0s 1 normal running;
}
.popbox img-f {
  max-height: 50vh;
  overflow: hidden;
  padding: 10px;
}
.buttonBar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
.closednav {
  background-color: var(--dark)!important;
  border: 2px solid var(--light);
  padding: 0!important;
}
.closednav:hover {
  background-color: var(--prim)!important;
}
.fulltog {
  max-width: 100%;
  margin: 0;
  padding: 5px 7px;
  font-size: 1rem!important;
  border-radius: 0;
}
.bigtog {
  font-size: 1rem;
  font-weight: 800;
  padding: 1px!important;
  width: 100%!important;
  text-align: center!important;
  margin: 0;
  background-color: var(--dark)!important;
  color: var(--light)!important;
}
.bigtog:hover {
  background-color: var(--prim)!important;
  color: var(--light)!important;
}
.subbord {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  max-width: 10%!important;
  border-color: var(--light)!important;
}
.subchat {
  display: none!important;
}
#CUC {
  max-width: 40%;
  font-size: .8rem;
  background-color: var(--dark);
  color: var(--prim);
  border-radius: 12px;
  padding: 3px 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  justify-self: center;
  margin: 0;
  font-weight: 800;
  text-align: center;
  box-shadow: 1px 2px 3px var(--prim);
  transition: 400ms ease;
  cursor: pointer;
}
#CUC:hover {
  color: var(--light);
  transition: 200ms ease-out;
  transform: scale(.9,.9);
}
h4 {
  font-weight: 800;
  font-size: .9rem;
}
@media screen and (max-width: 980px) {
  .imageForm {
    padding-top: 100px;
  }

}
@media screen and (min-width: 1080px) {
  #profCreate {
    flex-direction: row;
  }
}
@media screen and (min-width: 650px) {
  #chat {
    width: 60%;
    padding: 0px 15%;
    border: none;
  }
  .buttonBar {
    flex-direction: row;
    justify-content: space-around;
  }
  .messages {
    max-width: 40%;
    border: 3px solid var(--prim);
  }
}
.imageForm label {
  text-align: center;
  color: var(--dark);
  font-weight: 800;
  margin-bottom: -10px;
}
.CPUF label {
  margin: 0;
  padding: 0;
  text-align: center;
  color: var(--prim);
  font-weight: 800;
  font-size: 1.2rem;
}
#profCreate button {
  font-size: 1rem;
  padding: 8px 10px;
}
@media screen and (max-width: 650px) {
  #navlist li {
    margin: 20px 0px;
  }
  .subbord {
    max-width: 25%!important;
  }
  #PPreview {
    margin-top: 40px;
    max-width: 70%;
  }
  #profilePic {
    max-height: 40px;
  }
  #form1 .b2 {
    max-width: 100%;
    padding: 10px;
    width: auto;
  }
  .CPUF {
    max-width: 100%;
  }
  .fulltog {
    font-size: 1rem!important;
  }
  .bigtog {
    font-size: 1rem;
  }
  h2 {
      font-size: .9rem;
  }
  .imageForm {
    padding-top: 40px;
  }
  .newtodo {
    top: 20px;
    right: 10px;
  }
  .lefttodo {
    top: 20px;
    left: 10px;
  }
  textarea {
    padding: 10px;
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 0px;
  }
  .messages {
    max-height: 80vh;
  }
  #im-form h2 {
    padding: 0;
  }
}
@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(3000px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadedown {
  from {
    opacity: 0;
    transform: translateY(-3000px);
  }
  80% {
    opacity: .4
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideright {
  from {
    opacity: 0;
    transform: translateX(3000px);
  }
  80% {
    opacity: .4
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}