.profileCont {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    max-width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: auto;
    max-height: 100vh;
    z-index: 1;
    background-color: var(--light);
    overflow-y: scroll!important;
    overflow-x: hidden;
}
#profHead {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 20px;
}
.b3 {
    max-width: 50%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profileCont button {
    padding: 5px 7px;
    background-color: none;
    color: var(--prim);
    font-weight: 800;
    font-size: .9rem;
    letter-spacing: 2px;
    transition: 300ms;
}
.profileCont span {
    max-width: 20%;
    text-align: center;
    margin-bottom: 12px;
    font-weight: 800;
    color: var(--dark);
    cursor: pointer;
}
#closeBasics {
    color: var(--prim);
    cursor: pointer;
}
#CP label {
    text-align: center;
}
.post {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}
#editPostForm text-area {
    text-align: left!important;
    padding: 0!important;
}
.breaker {
    width: 100%;
    height: 50px;
    max-width: 100%;
    overflow: hidden;
}
.post p {
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 1px;
    max-width: 100%;
    padding: 10px;
}
#editPost {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
#postwall {
    padding-top: 20px;
    padding-bottom: 10%;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}
.postInteractions {
    background-color: var(--prim);
    border-radius: 12px;
    width: 40%;
    max-width: 40%;
    justify-self: center;
    box-shadow: 1px 2px 3px #00000040;
}
.post h2 {
    color: var(--prim);
    font-size: 2rem;
    text-shadow: none;
    margin: 20px 0;
}
#CP input {
    max-width: 80%;
}
@media screen and (min-width: 650px) {
    #CP image-selector {
        max-width: 80%;
    }
}
search-icon {
    cursor: pointer;
}